import { openConsultantCianChat, openErrorMessage, setChatLoading } from './actions';
import { TThunkAction } from '../../types/redux';
import { onOpenChatPopup } from 'shared/containers/ConsultantCianChat/analytics/analytics';
import { EChatRole } from 'shared/repositories/newbuilding-consultants/entities/chat_user/ChatUser';
import { customLabelSelector } from 'shared/selectors/consultantCianChat';
import { selectFullUrl } from 'shared/selectors/results';
import { fetchConsultantCianChat } from 'shared/services/fetchConsultantCianChat/fetchConsultantCianChat';

export const requestChat = (): TThunkAction => {
  return async (dispatch, getState, context) => {
    dispatch(setChatLoading());
    const state = getState();
    const customLabel = customLabelSelector(state);
    const fullUrl = selectFullUrl(state);
    const { httpApi, logger } = context;
    const data = await fetchConsultantCianChat({ httpApi, logger, parameters: { pageUrl: fullUrl } });

    /* istanbul ignore else */
    if (data && data.chatId) {
      const user = data.users.find(user => user.chatRole === EChatRole.Initiator);
      dispatch(openConsultantCianChat(data.chatId));
      onOpenChatPopup({ label: customLabel, chatId: data.chatId, botId: 3, ownerId: user?.userId });
    } else {
      dispatch(openErrorMessage());
    }
  };
};
